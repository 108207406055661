let origin = "https://future.zhuneng.cn";
//积分
const pointsUrl = `/gateway/hc-accumulated/pointuser/getPointUserDetail`;
//获取商品列表
const getGoodsListUrl = `/gateway/hc-serve/miniapi/pointsMall/list`;
//获取商品详情
const getGoodsDetailUrl = `/gateway/hc-serve/miniapi/pointsMall/goodsDetailById`;
//积分兑换商品
const getChangeUrl = `/gateway/hc-serve/miniapi/pointsMall/exchangeGoods`;
//订单详情页
const getOrderDetailUrl = `/gateway/hc-serve/miniapi/pointsMall/userPointExchangeDetail`;
//积分订单列表
const getOrderListUrl = `/gateway/hc-serve/miniapi/pointsMall/userPointExchangeList`;
//核销
const handleOrderUrl = `/gateway/hc-serve/miniapi/pointsMall/sceneOrderCompleted`;
// 验证志愿者身份接口
const volunteerUrl = `/gateway/hc-serve/miniapi/volunteer/verificationVolunteer`;
//积分记录
const pointsList = `/gateway/hc-accumulated/pointrecord/getPointRecordList`;
//积分排行榜
const pointRankListUrl = `/gateway/hc-accumulated/pointuser/getPointUserRankingList`;
export {
  pointRankListUrl,
  pointsList,
  getChangeUrl,
  getGoodsListUrl,
  getGoodsDetailUrl,
  pointsUrl,
  getOrderDetailUrl,
  getOrderListUrl,
  handleOrderUrl,
  volunteerUrl,
};
